import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Box, Button, Chip, Typography, Unstable_Grid2 as Grid
} from '@mui/material';
import {
    EditOutlined as EditIcon,
    ClearRounded as ClearIcon,
    Add as AddIcon,
    UploadFile as UploadIcon
} from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { SelectElement, TextFieldElement, useForm } from 'react-hook-form-mui';

import ConfirmationDialog from '../../../components/ConfirmationDialog';
import DefaultDialog from '../../../modules/DefaultDialog';
import RequestButton from '../../../components/RequestButton';
import InputFileButton from '../../../components/InputFileButton';
import { DialogTypes, useDialog } from '../../base/Dialog';

export default function Documents(props) {
    const { createDialog } = useDialog();
    const { getData, submitCategory, submitDocument } = props;
    const { formatMessage } = useIntl();
    const [data, setData] = React.useState(null);
    const [showAddCategory, setShowAddCategory] = React.useState(false);
    const [showAddDocument, setShowAddDocument] = React.useState(false);
    const [deleteDocument, setDeleteDocument] = React.useState(null);
    const [isEditing, setIsEditing] = React.useState(false);

    React.useEffect(() => {
        getData().then(setData);
    }, []);

    const {
        control,
        handleSubmit,
        setValue
    } = useForm({ defaultValues: getData });

    const addCategorySuccess = async () => {
        setShowAddCategory(false);
        setValue('categoryName', '');
        const newData = await getData();
        setData(newData);
    };

    const deleteCategory = async (category) => {
        await submitCategory(category, true);
        const newData = await getData();
        setData(newData);
    };

    const addOrUpdateDocumentSuccess = async () => {
        setShowAddDocument(false);
        setIsEditing(false);
        setValue('documentName', '');
        setValue('documentCategory', '');
        setValue('documentDescription', '');
        const newData = await getData();
        setData(newData);
    };

    const showModelDocument = (documentData, isNew) => {
        if (!isNew) {
            const document = data.documents.find((doc) => doc.id === documentData.id);
            setValue('currentDocumentId', documentData.id);
            setValue('documentName', document.name);
            setValue('documentCategory', document.category);
            // TEMPORARY DISABLED
            // setValue('documentDescription', document.description);
            setIsEditing(true);
        } else {
            setValue('currentDocumentId', '');
            setValue('documentName', '');
            setValue('documentCategory', '');
            // TEMPORARY DISABLED
            // setValue('documentDescription', '');
            setIsEditing(false);
        }
        setShowAddDocument(true);
    };

    const onHideDeleteDocument = async (documentData, confirmed) => {
        if (confirmed) {
            setDeleteDocument(null);
            await submitDocument(documentData, true);
            const newData = await getData();
            setData(newData);
        }
        setDeleteDocument(null);
    };

    const isDummyData = (row) => row.id === 'N/A';

    const documentColumns = [
        {
            field: 'number',
            headerName: formatMessage({ defaultMessage: 'No.', id: 'newsstand.supplements.documents.number' }),
            flex: 0.3,
            sortable: false
        },
        {
            field: 'name',
            headerName: formatMessage({ defaultMessage: 'Name', id: 'newsstand.supplements.documents.name' }),
            flex: 1,
            sortable: false
        },
        {
            field: 'category',
            headerName: formatMessage({
                defaultMessage: 'Category',
                id: 'newsstand.supplements.documents.category'
            }),
            flex: 1,
            sortable: false,
            valueGetter: (value) => data.categories.find((c) => c.id === value.value)?.label
        },
        {
            field: 'date',
            headerName: formatMessage({
                defaultMessage: 'Date added',
                id: 'newsstand.supplements.documents.dateAdded'
            }),
            flex: 0.6,
            sortable: false
        },
        {
            field: 'pages',
            headerName: formatMessage({ defaultMessage: 'Pages', id: 'newsstand.supplements.documents.pages' }),
            flex: 0.4,
            sortable: false,
            headerAlign: 'right',
            align: 'right'
        },
        {
            field: 'actions',
            headerName: '',
            flex: 0.4,
            sortable: false,
            align: 'right',
            renderCell: ({ row }) => {
                const isDisabled = isDummyData(row);
                return (
                    <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'flex-end' }}>
                        <Button
                            disabled={isDisabled}
                            onClick={() => { showModelDocument(row, false); }}
                            size='small'
                            startIcon={<EditIcon />}
                            variant='onlyIcon'
                        />
                        <Button
                            disabled={isDisabled}
                            onClick={() => { setDeleteDocument(row); }}
                            size='small'
                            startIcon={<ClearIcon />}
                            variant='onlyIcon'
                        />
                    </Box>
                );
            }
        }
    ];

    /**
     * It looks weird when the last row has the same background color as the container,
     * so based on the row amount we decide if the even or odd rows have a different color
      */
    const rowCount = data?.documents?.length || 0;
    const evenRowCount = rowCount % 2 === 0;
    let evenRowColor;
    let oddRowColor;
    if (evenRowCount) {
        evenRowColor = 'white';
        oddRowColor = 'inherit';
    } else {
        evenRowColor = 'inherit';
        oddRowColor = 'white';
    }

    return (
        <Grid className='alternate-rows' container direction='column' spacing={3} sx={{ px: 2 }} xs={12}>
            <Grid container>
                <Grid xs={3}>
                    <Typography variant='sectionHeader'>
                        <FormattedMessage
                            defaultMessage='Categories'
                            id='newsstand.supplements.documents.categories'
                        />
                    </Typography>
                </Grid>
                <Grid xs={9}>
                    <Box display='flex'>
                        {data?.categories?.map((category) => (
                            <Chip
                                key={category.id}
                                deleteIcon={<ClearIcon />}
                                label={category.label}
                                onDelete={() => {
                                    const dialog = createDialog({
                                        type: DialogTypes.Confirm
                                    });

                                    dialog.onConfirm(() => {
                                        deleteCategory(category);
                                    });

                                    dialog.show();
                                }}
                                sx={{ mr: 1 }}
                                variant='outlined'
                            />
                        ))}
                        <Chip
                            key='addCategory'
                            color='primary'
                            icon={<AddIcon />}
                            label='Add category'
                            onClick={() => { setShowAddCategory(true); }}
                            sx={{
                                mr: 1,

                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid container sx={{ py: 2, my: 1, mb: 2 }}>
                <Grid xs={3}>
                    <Typography variant='sectionHeader'>
                        <FormattedMessage
                            defaultMessage='Documents'
                            id='newsstand.supplements.documents.documents'
                        />
                    </Typography>
                </Grid>
                <Grid container sx={{ px: 3 }} xs={9}>
                    <Grid container direction='column' xs={12}>
                        <Grid>
                            <Button
                                onClick={() => { showModelDocument(null, true); }}
                                startIcon={<AddIcon />}
                                variant='contained'
                            >
                                <FormattedMessage
                                    defaultMessage='Add document'
                                    id='newsstand.supplements.documents.addDocument'
                                />
                            </Button>
                        </Grid>
                        <Grid>
                            <DataGrid
                                columns={documentColumns}
                                disableColumnMenu
                                hideFooter
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'number', sort: 'asc' }],
                                    },
                                }}
                                loading={!data}
                                rows={data?.documents || []}
                                sx={{
                                    border: 'none',
                                    '& .MuiDataGrid-columnHeaders': {
                                        borderRadius: 1
                                    },
                                    '& .MuiDataGrid-columnHeader': {
                                        backgroundColor: 'common.white'
                                    },
                                    '& .MuiDataGrid-row': {
                                        '&:nth-of-type(even)': {
                                            backgroundColor: evenRowColor
                                        },
                                        '&:nth-of-type(odd)': {
                                            backgroundColor: oddRowColor
                                        }
                                    },
                                    '& .MuiDataGrid-cell': {
                                        borderBottom: 'none'
                                    },
                                    '& .MuiDataGrid-columnHeader:focus': {
                                        outline: 'none',
                                    },
                                    '& .MuiDataGrid-cell:focus': {
                                        outline: 'none',
                                    },
                                    height: 380
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid container>
                <Grid
                    display='flex'
                    justifyContent='flex-end'
                    xs={12}
                >
                    <Button
                        size='large'
                        type='submit'
                        variant='contained'
                    >
                        <FormattedMessage defaultMessage='Update' id='common.update' />
                    </Button>
                </Grid>
            </Grid> */}
            <DefaultDialog
                closeHandler={() => { setShowAddCategory(false); }}
                saveButton={(
                    <RequestButton
                        injectFormData={handleSubmit}
                        onSuccess={addCategorySuccess}
                        sendData={submitCategory}
                        size='large'
                        type='submit'
                        variant='contained'
                    >
                        <FormattedMessage
                            defaultMessage='Add category'
                            id='newsstand.supplements.documents.addCategory'
                        />
                    </RequestButton>
                )}
                show={showAddCategory}
                size='modalSm'
                title={formatMessage({
                    defaultMessage: 'Add category',
                    id: 'newsstand.supplements.documents.addCategory'
                })}
            >
                <TextFieldElement
                    control={control}
                    label={formatMessage({
                        defaultMessage: 'Category name', id: 'newsstand.supplements.documents.categoryName'
                    })}
                    name='categoryName'
                    required
                    type='text'
                />
            </DefaultDialog>
            <DefaultDialog
                closeHandler={() => { setShowAddDocument(false); }}
                saveButton={(
                    <RequestButton
                        injectFormData={handleSubmit}
                        onSuccess={addOrUpdateDocumentSuccess}
                        sendData={submitDocument}
                        size='large'
                        type='submit'
                        variant='contained'
                    >
                        {isEditing ? (
                            <FormattedMessage
                                defaultMessage='Update document'
                                id='newsstand.supplements.documents.updateDocument'
                            />
                        ) : (
                            <FormattedMessage
                                defaultMessage='Add document'
                                id='newsstand.supplements.documents.addDocument'
                            />
                        )}
                    </RequestButton>
                )}
                show={showAddDocument}
                size='modalSm'
                title={isEditing ? (
                    <FormattedMessage
                        defaultMessage='Update document'
                        id='newsstand.supplements.documents.updateDocument'
                    />
                ) : (
                    <FormattedMessage
                        defaultMessage='Add document'
                        id='newsstand.supplements.documents.addDocument'
                    />
                )}
            >
                <Grid container direction='column' spacing={3}>
                    <Grid>
                        <TextFieldElement
                            control={control}
                            label={formatMessage({
                                defaultMessage: 'Document name', id: 'newsstand.supplements.documents.documentName'
                            })}
                            name='documentName'
                            required
                            type='text'
                        />
                    </Grid>
                    <Grid>
                        <SelectElement
                            control={control}
                            label={formatMessage({
                                defaultMessage: 'Select a category',
                                id: 'newsstand.supplements.documents.selectCategory'
                            })}
                            name='documentCategory'
                            options={data?.categories || []}
                            required
                        />
                    </Grid>
                    {/* TEMPORARY DISABLED */}
                    {/* <Grid> */}
                    {/*     <TextFieldElement */}
                    {/*         control={control} */}
                    {/*         label={formatMessage({ */}
                    {/*             defaultMessage: 'Description', id: 'common.description' */}
                    {/*         })} */}
                    {/*         multiline */}
                    {/*         name='documentDescription' */}
                    {/*         required */}
                    {/*         type='text' */}
                    {/*     /> */}
                    {/* </Grid> */}
                    {!isEditing && showAddDocument && (
                        <Grid>
                            <Box display='flex' sx={{ alignItems: 'center' }}>
                                <InputFileButton
                                    accept='.pdf'
                                    control={control}
                                    name='documentFile'
                                    required
                                    size='large'
                                    startIcon={<UploadIcon />}
                                    sx={{ mr: 3 }}
                                    variant='outlined'
                                >
                                    <FormattedMessage
                                        defaultMessage='Upload'
                                        id='common.upload'
                                    />
                                </InputFileButton>
                                <Typography variant='blockSubtitle'>
                                    <FormattedMessage
                                        defaultMessage='File format: *.pdf'
                                        id='newsstand.supplements.uploadFile.allowedTypes'
                                    />
                                </Typography>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </DefaultDialog>
            <ConfirmationDialog
                disableAnimation
                isOpen={!!deleteDocument}
                onClose={() => onHideDeleteDocument(deleteDocument, false)}
                onConfirm={() => onHideDeleteDocument(deleteDocument, true)}
            />
        </Grid>
    );
}
Documents.propTypes = {
    getData: PropTypes.func.isRequired,
    submitCategory: PropTypes.func.isRequired,
    submitDocument: PropTypes.func.isRequired,
};
